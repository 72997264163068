<script setup>
//import side bar item
import SideBarItem from "./SideBarItem.vue"
import {AlphaStack,MediaCard, Modal, ModalSection} from "@ownego/polaris-vue"
import {ref, computed, watch} from "vue"
import { useAppStore } from '../store'
const active = ref(false);
const store = useAppStore();
const currentHandImageId = computed(()=> {
    return store.handImage;
});
const currentHandImage = ref(store.getCurrentHandImage);
const toggleActive = () => {
    active.value = !active.value;
};
const setHand = (handID) => {
    store.handImage= handID;
    toggleActive();
};
//watch for changes in currentHandImageId
watch(currentHandImageId, () => {
    currentHandImage.value = store.getCurrentHandImage;
});
</script>

<template>
    <SideBarItem>
        <alpha-stack><Heading></Heading><MediaCard
        title=""
        size="small"
        :primaryAction="{
            content: 'Select Hand',
            onAction: () => {
                active =!active
            },
        }"
        >
        <img
    :style="{ objectFit: 'cover', objectPosition: 'center', width: '80%' }"
    :src="currentHandImage"
  />
    </MediaCard></alpha-stack>
    </SideBarItem>


    <Modal
        :open="active"
        @close="toggleActive"
    >
        <template #content>
        <ModalSection>
            <span>
                    <img @click="setHand(1)" class="image-card" src="../assets/images/hands/1.png" alt="hand 1" />
                    <img @click="setHand(2)" class="image-card" src="../assets/images/hands/2.png" alt="hand 2" />
                    <img @click="setHand(3)" class="image-card" src="../assets/images/hands/3.png" alt="hand 3" />
                    <img @click="setHand(4)" class="image-card" src="../assets/images/hands/4.png" alt="hand 4" />
                    <img @click="setHand(5)" class="image-card" src="../assets/images/hands/5.png" alt="hand 5" />
                    <img @click="setHand(6)" class="image-card" src="../assets/images/hands/6.png" alt="hand 6" />
            </span>
        </ModalSection>
        </template>
  </Modal>
</template>

<style>
    .image-card{
        width:30%;
        border: 1px solid #ccc;
        cursor:pointer;
    }
</style>