<script setup>
//import side bar item
import SideBarItem from "./SideBarItem.vue"
import {AlphaStack,MediaCard} from "@ownego/polaris-vue"
import { useAppStore } from '../store';
import { computed } from "vue";
import axios from "axios";
const store = useAppStore();
const currentLogoURL = computed(()=>store.logoURL);
const onFileChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
        //convert to base64
        //send to server
        const payload = {
            nvznID: store.getAccountId,
            logoURL: reader.result,
        }
        axios.post(`${store.API_URL}upload_logo`, payload)
            .then((response) => {
                //set logoURL in store
                const logoURL = response.data.logoURL;
                console.log(logoURL);
                store.logoURL = logoURL;
            })
            .catch((error) => {
                console.log(error);
            });
    };
};
</script>

<template>
    <SideBarItem>
        <alpha-stack><Heading></Heading>             <MediaCard
        title=""
        size="small"
        :primaryAction="{
            content: 'Upload Logo',
            onAction: () => {
                //open file upload dialog to select file
                $refs.file.click();
            },
        }"
        >
        <img
    :style="{ objectFit: 'cover', objectPosition: 'center', width: '80%' }"
    :src="currentLogoURL"
  />    
        <input type="file" id="file" ref="file" style="display:none" @change="onFileChange" />
    </MediaCard></alpha-stack>
    </SideBarItem>
</template>