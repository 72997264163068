<template>
  <div class="players-container">
  <desktop-view :style="desktopViewStyles"/>
  <mobile-view :style="mobileViewStyles"/>
</div>
</template>
<script setup>
  import { ref, computed} from 'vue';
  import { useAppStore } from '../store'
  import DesktopView from './DesktopView'
  import MobileView from './MobileView'
  const store = useAppStore();
  const currentURL = ref(store.currentURL);
  
  const desktopViewStyles = computed(()=> {
    if (store.viewMode === 1) {
      return {
        display: 'flex'
      }
    } else {
      return {
        display: 'none'
      }
    }
  });
  const mobileViewStyles = computed(()=> {
    if (store.viewMode === 2) {
      return {
        display: 'flex'
      }
    } else {
      return {
        display: 'none'
      }
    }
  });
  store.$subscribe(() => {
    currentURL.value = store.currentURL;
});
</script>

<style scoped>

/*use opposite values to side-bar for player-container */
.players-container {
   margin-left: 400px;
   position:relative;
   overflow-x:none;
   z-index: 0;
   
}
</style>