import { defineStore } from 'pinia';
//import hand images from assets and put them in an array
//import background images from assets and put them in an array
//import all images in  './assets/images/hands' in vue3 without globEager
import hand1 from './assets/images/hands/1.png'
import hand2 from './assets/images/hands/2.png'
import hand3 from './assets/images/hands/3.png'
import hand4 from './assets/images/hands/4.png'
import hand5 from './assets/images/hands/5.png'
import hand6 from './assets/images/hands/6.png'
import background1 from './assets/images/background/1.png'
import background2 from './assets/images/background/2.png'
import background3 from './assets/images/background/3.png'
import background4 from './assets/images/background/4.png'
import background5 from './assets/images/background/5.png'
import background6 from './assets/images/background/6.png'
import background7 from './assets/images/background/7.png'
import background8 from './assets/images/background/8.png'
import logo from "./assets/images/logo-nvzn-2.jpg"
import currentImage from './assets/images/placeholder-artwork.jpg'
const handsArray = [hand1,hand2,hand3,hand4,hand5,hand6];
const backgroundArray = [background1,background2,background3,background4,background5 ,background6,background7,background8];
//get current base url
const currentURL = window.location.origin;
//const currentURL = 'http://localhost:8000';
const API_URL = `${currentURL}/`;
const getAccountId = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const accountId = urlParams.get('a');
    return accountId;
}
const getStoreName = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const storeName = urlParams.get('store_name');
    return storeName;
}
export const useAppStore = defineStore('app',{
    state:()=> ({
            API_URL:API_URL,
            viewMode:1,
            getAccountId:getAccountId(),
            storeName:getStoreName(),
            backgroundImages:backgroundArray,
            handImages:handsArray,
            currentURL: `${API_URL}/index.html?i=https%3A%2F%2Fcdn.shopify.com%2Fs%2Ffiles%2F1%2F0619%2F6061%2F8183%2Ffiles%2Fearth.jpg%3Fv%3D15661652834867646222&p=1&w=10.0&h=15.0&u=in&a=${getAccountId()}`,
            handImage :1,
            backgroundImage:1,
            primaryColor:'6667aa',
            secondaryColor:'ffffff',
            buttonTextTransform:'none',
            headerTextTransform:'none',
            boxMargins:10,
            boxCorners:10,
            buttonCorners:10,
            logoURL:logo,
            buttonText:"View in your space",
            currentImage: currentImage,
            apiURL:API_URL,
    }),
    getters: {
        getCurrentHandImage(state){
            return handsArray[state.handImage-1]
        },
        getCurrentBackgroundImage(state){
            return backgroundArray[state.backgroundImage-1]
        },
        getCurrentLogoURL(state){
            //convert
            return state.logoURL
        } ,
        getCurrentPrimaryColor(state){
            return state.primaryColor
        },
        getCurrentSecondaryColor(state){
            return state.secondaryColor
        },
        getCurrentBoxMargins(state){
            return state.boxMargins
        },
        getCurrentBoxCorners(state){
            return state.boxCorners
        },
        getCurrentButtonCorners(state){
            return state.buttonCorners
        }
    },
    setters: {
        setHandImage(state,handImage){
            state.handImage = handImage
        },
        setBackgroundImage(state,backgroundImage){
            state.backgroundImage = backgroundImage
        },
        setPrimaryColor(state,primaryColor){
            state.primaryColor = primaryColor
        },
        setSecondaryColor(state,secondaryColor){
            state.secondaryColor = secondaryColor
        },
        setButtonTextTransform(state,buttonTextTransform){
            state.buttonTextTransform = buttonTextTransform
        },
        setHeaderTextTransform(state,headerTextTransform){
            state.headerTextTransform = headerTextTransform
        },
        setBoxMargins(state,boxMargins){
            state.boxMargins = boxMargins
        },
        setBoxCorners(state,boxCorners){
            state.boxCorners = boxCorners
        },
        setButtonCorners(state,buttonCorners){
            state.buttonCorners = buttonCorners
        },
        setLogoURL(state,logoURL){
            state.logoURL = logoURL
        }
    },
    actions: {
       async hydrate() {
            const apiURL = `${this.apiURL}get_config`;
            const urlParams = new URLSearchParams(window.location.search);
            const a = urlParams.get('a');
            const response = await fetch(`${apiURL}?a=${a}`);
            const res =await response.json();
            this.handImage = res.handImage || 1
            this.backgroundImage = res.backgroundImage || 1
            //this.primaryColor = res.primaryColor || '6667aa'
            //this.secondaryColor = res.secondaryColor || 'ffffff'
            this.primaryColor = '6667aa'
            this.secondaryColor = 'ffffff'
            this.buttonTextTransform = res.buttonTextTransform || 'none'
            this.headerTextTransform = res.headerTextTransform || 'none'
            this.boxMargins = res.boxMargins || 10
            this.boxCorners = res.boxCorners || 10
            this.buttonCorners = res.buttonCorners || 10
            this.buttonText = res.buttonText || "View On Your Wall"
            this.logoURL = res.logoURL || logo
        }
    }
  })