

<script setup>
import SideBar from "./SideBar.vue"
//import logo uploader
import LogoUploader from "./LogoUploader.vue"
import HandSelector from "./HandSelector.vue"
import BackgroundSelector from "./BackgroundSelector.vue";
//import PlayerStyles from "./PlayerStyles.vue";
import SaveButton from "./SaveButton.vue";
import PlayerComponent from "./PlayerComponent.vue";
import TopContent from "./TopContent.vue";
import {ref} from "vue";
import { useAppStore } from '../store';
const store = useAppStore();
const overlay = ref("display:none");
const spinner = ref("display:block");
const urlParams = new URLSearchParams(window.location.search);
const a = urlParams.get('a');
//const renderTrigger = ref(false);
const hydrateStore = async ()=>{
  //fetch from server
  if (a === null) {
    alert("Please provide account parameter in the URL");
    return;
  }

  store.hydrate();
  overlay.value = "display:block";
  spinner.value = "display:none";
  //renderTrigger.value != renderTrigger.value;
}
hydrateStore();
</script>

<template>
  <div class="overlay" :style=overlay>
  <side-bar>
    <top-content/>
    <logo-uploader/>
    <hand-selector/>
    <background-selector/>
    <!-- <player-styles/> -->
    <save-button/>
  </side-bar>
  <player-component/>
</div>
<div class="spinner" :style="spinner"></div>
</template>

<style scoped>
.overlay {
  display: none;
  flex-direction: row;
  height: 100vh;
  width: 100vw;
  background-color: #f5f5f5;
  z-index:999;
}
.spinner{
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
  width: 100px;
  height: 100px;
  border: 10px solid #f3f3f3;
  border-top: 10px solid #3498db;
  border-radius: 50%;
  animation: spin 2s linear infinite;
  z-index: 999;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
