<script setup>
//import side bar item
import SideBarItem from "./SideBarItem.vue"
import { Button } from "@ownego/polaris-vue";
import { useAppStore } from '../store';
import {ref} from 'vue';
import axios from 'axios';
const store = useAppStore();
const apiURL = `${store.apiURL}update_config`;
const renderTrigger = ref(false);
const updateConfig = () => {
    //get 'a' parameter from url search params
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    const a = urlParams.get('a');
    //if a is not found
    if (a === null) {
        alert("Please provide account parameter in the URL");
        return;
    }
    if (token === null) {
        alert("Invalid Token");
        return;
    }
    const payload = {
        logoURL: store.logoURL,
        primaryColor: store.primaryColor,
        secondaryColor: store.secondaryColor,
        boxMargins: store.boxMargins,
        boxCorners: store.boxCorners,
        buttonCorners: store.buttonCorners,
        currentURL: store.currentURL,
        handImage: store.handImage,
        backgroundImage: store.backgroundImage,
        nvznID: a,
        token: token,
        buttonText: store.buttonText,
        storeName: store.storeName,
    }
    axios.post(apiURL, payload)
        .then(() => {
            //get fileURL from json response
           // const fileURL = response.data.logoURL;
            renderTrigger.value != renderTrigger.value;
            //alert user
            alert("Config updated successfully");
        })
        .catch((error) => {
            console.log(error);
            //alert user
            alert("Error updating config");
        });
}

</script>

<template>
    <SideBarItem>

        <span><Button primary @click="()=>updateConfig()">Save</Button></span> 
    
    </SideBarItem>
</template>

<style scoped>
@import "primevue/resources/themes/bootstrap4-light-blue/theme.css";
</style>