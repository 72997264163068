<template>
    <!-- APP -->
    <div style="position:relative">
    <div id="app" class="fullsize-view middle" :style="viewStyles">
      <!-- DESKTOP VIEW -->
      <div id="desktop-view" class="fullsize-view middle" :style="viewStyles">
        <!-- DESKTOP BRIDGE -->
        <div id="bridge-modal" class="desktop-content-box">
          <div>
            <div :style="brandStyles" class="brand-logo top-edge"></div>
            <div :style="dualPanelStyles" class="dual-panel" style="overflow: hidden">
              <div class="panel-item" style="background-color: #ffffff">
                <h2>
                  <svg id="bridge-modal-ar-icon">
                    <use :href="arIconURL" />
                  </svg>
                  {{buttonText}}
                </h2>
                <p>
                  To view the artwork in your space, scan the QR code with your
                  mobile device camera
                </p>
                <div id="qrCode">
                  <img
                    :src="qrCodeImage"
                    width="320"
                    height="320"
                  />
                </div>
              </div>
              <div :style="bridgePreviewStyles" class="panel-item bridge-preview" >
              </div>
            </div>
            <div class="watermark light">
              Powered by
              <img
                class="watermark-logo"
                :src="waterMarkImage"
              />
            </div>
          </div>
        </div>
        <!-- /DESKTOP BRIDGE -->
      </div>
      <!-- /DESKTOP VIEW -->
    </div>
  </div>
    <!-- /APP -->

    <!-- CONFIG PREVIEW -->
</template>
<script setup>
  import { ref, computed,watch } from 'vue';
  import waterMarkImage from "../assets/images/NvznLogo-Horizontal-163x40.png"
  import qrCodeImage from "../assets/images/placeholder-qr-code.jpg"
  import {generateLayeredImages} from "../helpers/utils.js";
  import { useAppStore } from '../store';
  import arIcon from "../assets/images/icon-ar.svg"
  const arIconURL = computed(()=>{
    return `${arIcon}#icon`;
  })
  const store = useAppStore();
  const currentURL = computed(() => {
    return store.logoURL;
  });

  watch(store.logoURL, () => {
    currentURL.value =store.logoURL;
  });
  const bridgePreviewStyles = ref("");
  const boxCorners = computed(()=>{
    return `${store.boxCorners}px`
  });
  const backgroundImage = computed(()=>{
    return store.backgroundImage;
  });
  const handImage = computed(()=>{
    return store.handImage;
  });
  const buttonText = computed(()=>{
    return store.buttonText;
  });
  //watch for backgroundImage and handImage changes
  watch([backgroundImage,handImage],()=>{
    generateImage();
  })
  const primaryColor = computed(() => {
    return `#${store.primaryColor}`;
  });
  const secondaryColor = computed(() => {
    return `#${store.secondaryColor}`;
  });
  const generateImage=()=>{
    const image1 = store.getCurrentBackgroundImage;
    const image2 = store.currentImage;
    const image3 = store.getCurrentHandImage;
    generateLayeredImages(image1,image2,image3).then(image=>{
        //set background image
        bridgePreviewStyles.value = {
          backgroundImage: `url(${image})`,
        };
    })
  }
  generateImage();
  const brandStyles = computed(() => {
    return {
      backgroundImage: `url(${currentURL.value})`,
    };
  });
</script>
<style scoped>
:root {
  --primary-color: #6667aa;
  --secondary-color: #ffffff;
  --header-text-transform: capitalize;
  --button-text-transform: capitalize;
  --button-corners: 10px;
  --box-margins: 10px;
  --box-corners: 20px;
  --box-corners-top: 20px;
  --box-corners-bottom: 20px;
  --full-box-corners: 20px;
  --preview-image: url('https://art-customizer-backend-4oe6foyllq-uc.a.run.app/generate-layered-images/https%3A%2F%2Fdemo-dev-art-player-4oe6foyllq-uc.a.run.app%2Fassets%2Fimage%2Fplaceholder-artwork.jpg');
}
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');

html,


body {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #272727;
  margin: 0px;
  text-align: left;
}


a,
a:hover,
a:active,
a:visited {
  border: none;
  outline: none;
  color: #272727;
  -webkit-tap-highlight-color: transparent;
}


h1 {
  color: v-bind(secondaryColor);
  font-weight: bold;
}

h2,
h3 {
  color: v-bind(primaryColor);
  font-weight: bold;
}



h4 {
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.5;
  margin: 0px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: var(--header-text-transform);
}

h4 label {
  margin: 0px 10px;
}


h1,
h2,
h3 {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.5;
  margin: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 10px 10px 10px 10px;
  text-transform: var(--header-text-transform);
}

h1 svg,
h2 svg,
h3 svg {
  fill: currentColor;
  stroke: currentColor;
  width: 32px;
  height: 32px;
  margin: 0px 10px;
}

.ar-header-item {
  box-sizing: border-box;
  position: absolute;
  padding: 10px;
}

.ar-header-item.left {
  top: 0px;
  left: 0px;
}

.ar-header-item.right {
  top: 0px;
  right: 0px;
}

.justified {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.25);
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
}



.title {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.5;
  color: #fff;
  margin: 5px;
}

.instructions-body {
  font-size: 16px;
  line-height: 1.5;
  min-height: 4.5em;
  color: #272727;
  margin: 0px 5px 10px 5px;
  text-align: left;
}

.copy-centered {
  font-size: 16px;
  line-height: 1.5;
  color: #272727;
  margin: 10px;
  text-align: center;
}

#mobile-view {
  background-color: #101010;
}

.fullsize-view {
  position:absolute;
  top: 200px;
  left: 0px;
  width: 100%;
  height: 100%;
  margin: 0;
  box-sizing: border-box;
  /* Center */
  display: flex;
  justify-content: center;
}

.fullsize-view.middle {
  align-items: center;

}

.fullsize-view.bottom {
  align-items: flex-end;
}


#player {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #f7f8f9;
}



#content {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}



.backdrop {
  background-color: #181818;
  position: absolute;
  box-sizing: border-box;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 75%;
  background: linear-gradient(180deg, #10101000 0%, #10101000 75%, #101010 100%), var(--preview-image);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}


.contentbox {
  position: absolute;
  top: 0px;
  left: 0px;
  width: auto;
  height: 25%;
  padding: 10px;
  margin: 10px;
}

.mobile-content-box {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  max-width: 500px;
  padding: 15px;
  background-color: rgba(255, 255, 255, 0.85);
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  text-align: center;
  border-radius: v-bind(boxCorners) v-bind(boxCorners) v-bind(boxCorners) v-bind(boxCorners);
}

.mobile-content-box-middle {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  max-width: 400px;
  padding: 15px;
  background-color: rgba(255, 255, 255, 0.85);
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  text-align: center;
  border-radius: v-bind(boxCorners);

  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

}

.clear-content-box {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  max-width: 400px;
  padding: 15px;
  text-align: center;
}

.full-height-box {
  height: 100%;
  border-radius: var(--full-box-corners);
}



.mobile-panel {
  box-sizing: border-box;
  width: 100%;
  max-width: 400px;
  padding: 20px;
  text-align: center;
}

.content-container {
  position: fixed;
  padding: 10px;
  border-width: 1px;
  border-color: rgba(255, 0, 0, 1);
  border-style: solid;
}

.box-margins {
  ------padding: 15px;
}

.box-corners {
  border-radius: 100px;
}

.box-top-padding {
  padding-top: 40px;
}

.center-container {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  border: 3px solid green;
  display: flex;
  justify-content: center;
}

.ar-preview-container {
  width: 70%;
  height: 30%;
  position: absolute;
  top: 25%;
  transform: translateY(0%);
}

.brand-logo {
  display: inline-block;
  width: 70px;
  height: 70px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 50%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.brand-logo.small {
  width: 35px;
  height: 35px;
}

.top-edge {
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translate(-50%, -50%);
}


.ui-button {
  display: block;
  box-sizing: border-box;
  text-decoration: none;
  border: none;
  appearance: none;
  text-transform: var(button-text-transform);
}

.icon-button {
  display: block;
  box-sizing: border-box;
  width: 35px;
  height: 35px;
  margin: 0px;
  padding: 0px;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.icon-button svg {
  fill: currentColor;
  stroke: currentColor;
  width: 24px;
  height: 24px;
  margin: 0px;
  padding: 0px;
}

.ui-button.large {
  font-size: 16px;
  font-weight: bold;
  line-height: 2;
  padding: 8px;
  width: 85%;
  height: 50px;
  max-width: 100%;
  /* useful when width is set to anything other than 100% */
  margin: 10px;
  border-radius: var(--button-corners);
  text-transform: var(--button-text-transform);
}

.ui-button.small {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  padding: 8px;
  width: 75%;
  height: 45px;
  max-width: 100%;
  /* useful when width is set to anything other than 100% */
  margin: 10px 0px;
  border-radius: var(--button-corners);
  text-transform: var(button-text-transform);
}

.ui-button.primary {
  background-color: v-bind(primaryColor);
  color: v-bind(secondaryColor);
  text-transform: var(--button-text-transform);
}

.ui-button.secondary {
  background-color: v-bind(secondaryColor);
  color: v-bind(primaryColor);
  border-color: v-bind(primaryColor);
  border-width: 2px;
  border-style: solid;
  text-transform: var(--button-text-transform);
}

.ui-button svg {
  fill: currentColor;
  stroke: currentColor;
}

.ui-button.large svg {
  width: 32px;
  height: 32px;
  margin: 0px 10px;
}

.ui-button.small svg {
  width: 24px;
  height: 24px;
  margin: 0px 4px;
}

.ui-button.justified {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
}

.ui-button.centered {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.ui-button:disabled {
  background-color: rgb(120, 120, 120);
}


.text-button {
  display: inline-block;
  box-sizing: border-box;
  text-decoration: underline;
  border: none;
  appearance: none;
  font-size: 16px;
  line-height: 1.5;
  padding: 0px;
  width: 75%;
  max-width: 100%;
  /* useful when width is set to anything other than 100% */
  margin: 10px 0px;
  color: rgb(70, 70, 70);
  background-color: rgba(255, 0, 0, 0);
}





.dimensions {
  font-size: 14px;
  line-height: 1.5;
  color: #fff;
  margin: 5px 10px 10px 5px;
}

.small-label {
  font-size: 12px;
  font-weight: bold;
  line-height: 1.5;
  color: #fff;
  margin: 5px;
}



.watermark-logo {
  height: 24px;
  filter: grayscale(100%) opacity(100%);
}

.watermark {
  color: #656565;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.5;
  margin-top: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.watermark.light {
  opacity: 0.2;
}

.watermark.dark {
  opacity: 0.7;
}



.instructions-card {
  text-align: center;
}

.instructions-image {
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
}



.tns-nav {
  text-align: center;
  margin-top: 0px;
  margin-bottom: 0px;
}

.tns-nav button {
  width: 45px;
  height: 6px;
  border-radius: 0px;
  border: none;
  margin: 0 6px;
  background-color: #bbbbbb;
  padding: 0px;
}

.tns-nav button.tns-nav-active {
  background-color: v-bind(primaryColor);
}


.permissions-prompt {
  box-sizing: border-box;
  font-size: 14px;
  line-height: 1.5;
  color: #272727;
  margin: 0px;
  ---width: 100%;
  text-align: center;
  border-radius: 10px;
  background-color: #ffffff;
  ---padding: 10px;
}

.ar-preview-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  opacity: 0.5;
}

.art-preview-container {
  width: 100%;
  height: 200px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
  margin-bottom: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.art-preview-image {
  width: 80%;
  height: 80%;
  object-fit: contain;
  transform: perspective(400px) rotateY(20deg);
  filter: drop-shadow(0px 5px 8px rgba(0, 0, 0, 0.5));
}

.help {
  display: flex;
  flex-flow: column;
}

.help iframe {
  flex: 1;
  /* 1 and it will fill whole space left if no flex value are set to other children*/
  border-style: solid;
  border-width: 0px 0px 1px 0px;
  border-color: #808080;
  margin-bottom: 5px;
}

#desktop-view {
  background-color: #EFEEEE;
}



.desktop-content-box {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  max-width: 800px;
  padding: 0px;
  text-align: center;
}

.large-content-container {
  box-sizing: border-box;
  padding: 0px;
  background-color: rgba(200, 118, 181, 0.85);
  text-align: center;
  width: 800px;
}

.bridge-panel {
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  justify-content: center;
  width: 800px;
}

.dual-panel {
  box-sizing: border-box;
  display: flex;
  flex-flow: row;
  justify-content: center;
  border-radius: v-bind(boxCorners);
}

.panel-item {
  box-sizing: border-box;
  padding: 20px;
  flex: 1;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.bridge-preview {
  box-sizing: border-box;
  background: var(--preview-image);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.prompt-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 18px;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 100%;
}



.spin {
  animation: spin 1s infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.ar-scan-glyph-phone {
  position: relative;
  width: 100px;
}

.ar-scan-glyph-phone-container {
  background-color: white;
  width: 100px;
  height: 75px;
  border-style: solid;
  border-color: black;
  border-width: 2px;
  border-radius: 5px;
}

.ar-scan-glyph {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}


div.callout {
  background-color: #fbfbfb;
  position: relative;
  color: #000;
  padding: 10px;
  border-radius: var(--button-corners);
  margin: 15px 25px 10px 25px;
  min-height: 50px;
  filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.2));
}

.callout::before {
  content: "";
  width: 0px;
  height: 0px;
  border: 0.8em solid transparent;
  position: absolute;
}

.callout.top::before {
  left: 45%;
  bottom: -20px;
  border-top: 10px solid #fbfbfb;
}

.callout.bottom::before {
  left: 45%;
  top: -20px;
  border-bottom: 10px solid #fbfbfb;
}
</style>