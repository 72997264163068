export const generateLayeredImages = async (first_image, second_image, third_image) =>{
    console.log('generateLayeredImages')
    const overlayed_image = await overlayImages(first_image, second_image, third_image);
    return overlayed_image;
}

const overlayImages = async (first_image, second_image, third_image) =>{
    return new Promise((resolve, reject) => {
        const canvas = document.createElement('canvas');
        const tempCanvas = document.createElement('canvas');
        const tCtx = tempCanvas.getContext('2d');
        const ctx = canvas.getContext('2d');
        const image = new Image();
        canvas.width = image.width;
        canvas.height = image.height;
        ctx.drawImage(image, 0, 0);
        image.src = first_image;
        image.onload = () => {
            canvas.width = image.width;
            canvas.height = image.height;
            ctx.drawImage(image, 0, 0);
            const image2 = new Image();
            image2.src = second_image;
            image2.onload = () => {
                const maxWidth = 250;
                const maxHeight = 250;
                let height = 142;
                let width = image2.width * (height / image2.height);
                if(width > maxWidth)
                {
                    const ratio = maxWidth / width;
                    height = height * ratio;
                    width = maxWidth;
                }
                if (height > maxHeight)
                {
                    const ratio = maxHeight / height;
                    width = width * ratio;
                    height = maxHeight;
                }
                //add shadow to tCtx
                tCtx.shadowColor = 'black';
                tCtx.shadowBlur = 10;
                tCtx.shadowOffsetX = 5;
                tCtx.shadowOffsetY = 5;
                tCtx.drawImage(image2, 0, 0, width, height);
                //convert tCtx to iamge object
                ctx.globalCompositeOperation = 'source-over';
                //const offsetConstant = 23;
                const referenceWidth = 211.33;
                const offset = (referenceWidth - width)/2
                ctx.drawImage(tempCanvas, 220+offset, 160);
                if(third_image == null)
                    resolve(canvas.toDataURL('image/png'));
                else{
                    //overlay third image on top of second
                    const image3 = new Image();
                    image3.src = third_image;
                    image3.onload = () => {
                        ctx.globalCompositeOperation = 'source-over';
                        ctx.drawImage(image3, 0, 0);
                        //return canvas as base64 string
                        resolve(canvas.toDataURL('image/png'));
                    }
                }
            }
        }
        setTimeout(() => {
            reject('Error: Image loading timeout');
        }, 5000);
    });
}