<script setup>
//import side bar item
import SideBarItem from "./SideBarItem.vue"
import {MediaCard,Modal, ModalSection} from "@ownego/polaris-vue"
import {ref, computed, watch} from "vue"
import { useAppStore } from '../store'
const active = ref(false);
const store = useAppStore();
const currentBackgroundImageId = computed(()=> {
    return store.backgroundImage;
});
const currentBackgroundImage = ref(store.getCurrentBackgroundImage);
const toggleActive = () => {
    active.value = !active.value;
};
const setBackground = (bgID) => {
    store.backgroundImage= bgID;
    toggleActive();
};
//watch for changes in currentHandImageId
watch(currentBackgroundImageId, () => {
    currentBackgroundImage.value = store.getCurrentBackgroundImage;
});
</script>

<template>
    <SideBarItem>
        <MediaCard
        title=""
        size="small"
        :primaryAction="{
            content: 'Select Background',
            onAction: () => {
                active =!active
            },
        }"
        >
        <img
    :style="{ objectFit: 'cover', objectPosition: 'center', width: '70%' }"
    :src="currentBackgroundImage"
  />
    </MediaCard>
    <Modal
        :open="active"
        @close="toggleActive"
    >
        <template #content>
        <ModalSection>
            <span>
                    <img @click="setBackground(1)" class="image-card" src="../assets/images/background/1.png" alt="bg 1" />
                    <img @click="setBackground(2)" class="image-card" src="../assets/images/background/2.png" alt="bg 2" />
                    <img @click="setBackground(3)" class="image-card" src="../assets/images/background/3.png" alt="bg 3" />
                    <img @click="setBackground(4)" class="image-card" src="../assets/images/background/4.png" alt="bg 4" />
                    <img @click="setBackground(5)" class="image-card" src="../assets/images/background/5.png" alt="bg 5" />
                    <img @click="setBackground(6)" class="image-card" src="../assets/images/background/6.png" alt="bg 6" />
                    <img @click="setBackground(7)" class="image-card" src="../assets/images/background/7.png" alt="bg 7" />
                    <img @click="setBackground(8)" class="image-card" src="../assets/images/background/8.png" alt="bg 8" />

            </span>
        </ModalSection>
        </template>
  </Modal>
    </SideBarItem>
</template>

<style>
.Polaris-MediaCard__MediaContainer.Polaris-MediaCard--sizeSmall{
    display:flex;
    justify-content:center;
    align-items:center;
}
</style>