<template #secondaryMenu>
  <div class="button-container">
    <ButtonGroup class="b-class">    
      <Button class="icon-button" @click="enableDesktopView()"><img class='icon' :src="desktopIcon" alt="desktop icon" /><span class="button-text"><p>Desktop View</p></span></button>
      <Button class="icon-button" @click="enableMobileView()"><img class='icon' :src="mobileIcon" alt="mobile icon" /><span class="button-text"><p>Mobile View</p></span></button>
    </ButtonGroup>
  </div>
</template>

<script setup>
import DesktopIcon from "../assets/images/desktop_windows_FILL0_wght400_GRAD0_opsz48.svg";
import MobileIcon from "../assets/images/phone_iphone_FILL0_wght400_GRAD0_opsz48.svg";
import { useAppStore } from '../store';
import {Button, ButtonGroup} from "@ownego/polaris-vue"
const store = useAppStore();
const desktopIcon = DesktopIcon;
const mobileIcon = MobileIcon;
const enableDesktopView = () => {
    store.viewMode=1
}
const enableMobileView = () => {
    store.viewMode=2
}
</script>

<style scoped>
.icon-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.icon {
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.button-text {
  text-align: center;
}

.button-container {
  display:flex !important;
  background-color: white !important;
  justify-content: center;
  padding:5px;
}
</style>
